import React, { useState, useRef } from 'react';

import PortalModal from '@components/UI/portalModal';
import { motion } from 'framer-motion';
import { useAgeGate } from '../compForm';
import cx from 'classnames';
import CompText from './compText';
import TermsPopup from './termsPopup';

import Tick from '@svg/tick.svg';

export const AgeGate = () => {
  const { setAge } = useAgeGate();
  const [day, setDay] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(0);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [dateMessage, setDateMessage] = useState('');
  const [termsMessage, setTermsMessage] = useState('');

  const dayFocus = useRef();
  const monthFocus = useRef();
  const yearFocus = useRef();
  const termsFocus = useRef();
  const enterFocus = useRef();

  const handleClose = () => setOpen(false);
  const handleChange = () => {
    setChecked(!checked);
  };

  const underAgeValidate = (birthday) => {
    let birthDate = new Date(birthday);
    let ageDifMs = Date.now() - birthDate.getTime();
    let ageDate = new Date(ageDifMs);
    let age = Math.abs(ageDate.getUTCFullYear() - 1970);

    if (age > 17) {
      return true;
    } else {
      return false;
    }
  };

  const handleEnter = () => {
    const formattedDate = `${year}/${month}/${day}`;
    const isValid = underAgeValidate(formattedDate);

    //scroll to top of page
    window.scrollTo(0, 0);

    if (checked && isValid && year.length === 4) {
      setAge(true);
    } else if (year.length !== 4) {
      setDateMessage('Please enter your date of birth');
    } else if (!checked && !isValid) {
      setTermsMessage('Please accept the terms and conditions');
      setDateMessage('You must be over 18 to enter');
    } else if (!checked && isValid) {
      setTermsMessage('Please accept the terms and conditions');
      setDateMessage('');
    } else if (!isValid && checked) {
      setDateMessage('You must be over 18 to enter');
      setTermsMessage('');
    }
  };

  return (
    <>
      <motion.div className='px-gutter overflow-hidden  relative h-full flex justify-center items-start pb-52  md:pb-44 text-blue'>
        <div className='z-40 '>
          <div>
            <CompText isStart={true} />
          </div>
          <p className='uppercase mt-10  font-helvetica text-[20px] lg:text-[40px] text-center max-w-[700px] mx-auto'>
            SORRY, UNFORTUNATELY THE PROMOTION HAS NOW FINISHED. Please keep an
            eye out for future promotions.
          </p>
        </div>
      </motion.div>
    </>
  );
};
