import React from "react";
import { motion, AnimatePresence } from "framer-motion";

import Cross from "@svg/cross.svg";
import Insta from "@svg/insta.svg";

const Confirmation = ({ handleConfirmationClose }) => {
  return (
    <AnimatePresence>
      <div className="bg-beige w-full h-screen relative text-blue font-corona text-center flex items-center overflow-hidden">
        <div
          //   whileHover={{
          //     scale: 1.2,
          //   }}
          onClick={handleConfirmationClose}
          className="absolute top-5 right-1/2 translate translate-x-1/2 md:right-20 cursor-pointer z-50  rounded-full h-16 w-16 "
        >
          <Cross className="w-[29px] md:w-[40px] absolute top-1/2 left-1/2  -translate-y-1/2 -translate-x-1/2" />
        </div>
        <div className="w-full h-auto px-5">
          <div className=" md:mb-5">
            <h2 className="text-[50px] md:text-[70px] mb-5 md:mb-8">
              GOOD LUCK!
            </h2>
            <p className="text-[18px] md:text-[30px] leading-10">
              YOU'RE IN THE DRAW TO WIN A CORONA COOLER,
              <br />
              WE'LL BE IN TOUCH IF YOU'RE A LUCKY WINNER.
            </p>
          </div>
          <div className="flex flex-col space-y-5 md:space-y-8">
            <a
              className="button-three flex"
              href="https://www.instagram.com/corona_nz/"
              target="_blank"
              rel="noreferrer"
            >
              <span>FOLLOW US ON</span>

              <Insta className="ml-6 w-[20px] md:w-[47px] h-auto" />
            </a>
            <a
              href="https://www.corona.co.nz/collections/all"
              target="_blank"
              rel="noreferrer"
              className="button-three"
            >
              SHOP OUR MERCH
            </a>
          </div>
        </div>
      </div>
    </AnimatePresence>
  );
};

export default Confirmation;
