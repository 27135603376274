import React, { createContext, useContext, useState } from "react";
import { AnimatePresence } from "framer-motion";

import { EntryForm } from "./entryForm";
import { AgeGate } from "./ageGate";
import Footer from "./footer";

const initialState = {
  isAge: false,
  isSubmitted: false,
  formValues: {},
  error: false,
};

//

const isClient = typeof window === "object";

export const AppContext = createContext({
  compState: initialState,
  setAppState: () => null,
});

const FormInner = () => {
  const { isSubmitted, isAge, error } = useAppState();

  return (
    <>
      <AnimatePresence mode="wait">
        <div className="background-image">
          {!isAge ? (
            <AgeGate />
          ) : (
            <EntryForm error={error} isSubmitted={isSubmitted} />
          )}
          <Footer />
        </div>
      </AnimatePresence>
    </>
  );
};

const CompForm = () => {
  const [compState, setCompState] = useState(initialState);
  const [isLoading, setLoading] = useState(false);

  return (
    <AppContext.Provider
      value={{ compState, setCompState, isLoading, setLoading }}
    >
      <div>
        <FormInner />
      </div>
    </AppContext.Provider>
  );
};

function useAppState() {
  const { compState } = useContext(AppContext);
  return compState;
}

function useAppLoading() {
  const { isLoading } = useContext(AppContext);
  return isLoading;
}

function useAgeGate() {
  const { setCompState } = useContext(AppContext);

  function setAge() {
    setCompState((prevState) => ({
      ...prevState,
      isAge: true,
    }));
  }

  return { setAge };
}

function useAppForm() {
  const { setCompState, compState, setLoading } = useContext(AppContext);

  // Form Change Function
  function handleChange(event) {
    event.persist();
    setCompState((prevState) => ({
      ...prevState,
      formValues: {
        ...prevState.formValues,
        [event.target.name]: event.target.value.toUpperCase(),
      },
    }));
  }

  function handleSub(check) {
    setCompState((prevState) => ({
      ...prevState,
      formValues: {
        ...prevState.formValues,
        subscribe: check,
      },
    }));
  }

  async function fetchCompResult(data) {
    const response = await fetch("/.netlify/functions/handle-comp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return response;
  }

  // Form Handle Submit
  async function handleSubmit(event) {
    event.preventDefault();

    // Set Loading
    setLoading(true);
    // Await response from endpoint
    const { codeInvalid, weeklyLimit } = await fetchCompResult({
      ...compState.formValues,
    });

    isClient && window.scrollTo(0, 0);
    setLoading(false);

    setCompState((prevState) => ({
      ...prevState,
      isSubmitted: !codeInvalid && !weeklyLimit,
      error: codeInvalid ? true : false,
      error: codeInvalid ? "code" : weeklyLimit ? "weekly limit" : false,
    }));
  }

  // Provide a reset
  function handleReset() {
    isClient && window.scrollTo(0, 0);
    setCompState((prevState) => ({
      ...prevState,
      isSubmitted: false,
    }));
  }

  function handleErrorReset() {
    isClient && window.scrollTo(0, 0);
    setCompState((prevState) => ({
      ...prevState,
      error: false,
    }));
  }

  return {
    handleChange,
    handleSub,
    handleReset,
    handleErrorReset,
    handleSubmit,
  };
}

export { CompForm, useAppState, useAppForm, useAppLoading, useAgeGate };
