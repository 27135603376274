import React from "react";

import Cheers from "@images/cheers.png";
import CoronaBottles from "@images/corona-bottles.png";

const Footer = () => {
  return (
    <div className="absolute xl:fixed bottom-0 left-0 w-full">
      <div className="bg-blue-dark pb-[36px] md:pb-[20px] flex justify-end px-10 md:px-20 relative z-30">
        <div className="absolute left-0 bottom-0 pointer-events-none">
          <img src={CoronaBottles} alt="" className="w-[173px] md:w-[295px] " />
        </div>
        <div className="">
          <h4 className=" md:hidden font-grotesque text-white leading-10 tracking-[0.2em] text-[10px]">
            DRINK RESPONSIBLY
          </h4>
          <a href="https://cheers.org.nz/" target="_blank">
            <img src={Cheers} alt="" className="w-[82px] mx-auto" />
          </a>
          <h4 className="hidden md:block font-grotesque text-white leading-10 tracking-[0.2em] text-[15px]">
            DRINK RESPONSIBLY
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Footer;
