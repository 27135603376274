import React from "react";
import { AnimatePresence } from "framer-motion";

import Cross from "@svg/cross.svg";

const ErrorPopup = ({ handleErrorClose, error }) => {
  return (
    <AnimatePresence>
      <div className="bg-beige text-blue font-corona my-10 max-w-[1200px] max-h-[600px] m-auto self-center   relative rounded-[30px]">
        <div
          onClick={handleErrorClose}
          className="absolute top-5 right-1/2 translate translate-x-1/2 md:right-20 cursor-pointer z-50  rounded-full h-16 w-16 "
        >
          <Cross className="w-[29px] md:w-[40px] absolute top-1/2 left-1/2  -translate-y-1/2 -translate-x-1/2" />
        </div>
        <div className="px-gutter md:px-40 py-28  lg:py-[181px] w-[90vw] mx-auto lg:w-auto text-center  ">
          <h1 className=" text-[50px] md:text-[70px] pb-5">OOPS!</h1>
          {error === "weekly limit" ? (
            <p className="uppercase text-[18px] md:text-[30px]">
              Only one entry per week per person.
            </p>
          ) : (
            <p className="uppercase text-[18px] md:text-[30px]">
              Looks like this code is incorrect or has already been used.
              <br />
              Please enter another code to continue.
            </p>
          )}
        </div>
      </div>
    </AnimatePresence>
  );
};

export default ErrorPopup;
