import React from "react";
import { useTermsQuery } from "@querys/useTermsQuery";
import { AnimatePresence } from "framer-motion";
import SimplePortableText from "@components/sanity/simplePortableText";

import Cross from "@svg/cross.svg";

const TermsPopup = ({ handleClose }) => {
  const { title, text } = useTermsQuery();
  return (
    <AnimatePresence>
      <div className="bg-beige my-10 w-full h-full self-center   relative  overflow-y-scroll font-corona text-blue">
        <div
          onClick={handleClose}
          className="absolute top-5 right-1/2 translate translate-x-1/2 md:right-20 cursor-pointer z-50  rounded-full h-16 w-16 "
        >
          <Cross className="w-[29px] md:w-[40px] absolute top-1/2 left-1/2  -translate-y-1/2 -translate-x-1/2" />
        </div>
        <div className="px-4 py-28  lg:py-[181px]  lg:px-[161px]  ">
          <h1 className="font-bold text-[30px] md:text-[40px] text-blue-dark text-center pb-16">
            {title}
          </h1>

          <SimplePortableText text={text} className="text-blue-dark" />
        </div>
      </div>
    </AnimatePresence>
  );
};

export default TermsPopup;
