import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import { useAppForm, useAppLoading } from "./";
import { Spinner } from "./spinner";
import CompText from "./compText";
import PortalModal from "@components/UI/portalModal";
import TermsPopup from "./termsPopup";
import Confirmation from "./confirmation";
import ErrorPopup from "./errorPopup";

import cx from "classnames";

import Tick from "@svg/tick.svg";

export const EntryForm = ({ error, isSubmitted }) => {
  const {
    handleChange,
    handleSub,
    handleSubmit,
    handleReset,
    handleErrorReset,
  } = useAppForm();
  const [checked, setChecked] = useState(true);
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  const codeRef = useRef();

  const handleClose = () => setOpen(false);

  const handleConfirmationClose = () => {
    handleReset();
    setConfirmationOpen(false);
    // clear codeRef
    codeRef.current.value = "";
  };

  const isLoading = useAppLoading();

  const handleErrorClose = () => {
    handleErrorReset();
    setErrorOpen(false);
  };

  const handleChecked = () => {
    setChecked(!checked);
  };

  // set Sub state to true on first load
  useEffect(() => {
    return handleSub(true);
  }, []);

  useEffect(() => {
    if (error) {
      setErrorOpen(true);
    }
  }, [error]);

  useEffect(() => {
    if (isSubmitted) {
      setConfirmationOpen(true);
    }
  }, [isSubmitted]);

  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 10 }}
        className="h-full overflow-hidden w-full   "
      >
        <div className="relative">
          <CompText />

          <div className="relative pb-80 md:pb-[271px] z-20">
            <div className="">
              <form className="" onSubmit={handleSubmit} autocomplete="off">
                <div>
                  {/* Form */}
                  <div className="flex flex-col  md:grid grid-cols-2 gap-y-4 gap-x-6 mt-6 max-w-[600px] mx-auto px-6">
                    <div class="col-span-2 relative z-0  w-full group">
                      <input
                        name="uniqueCode"
                        type="text"
                        required
                        placeholder=" "
                        onChange={handleChange}
                        className="codeInput peer"
                        ref={codeRef}
                      />
                      <label
                        for="uniqueCode"
                        className="codeLabel pointer-events-none "
                      >
                        UNIQUE CODE*
                      </label>
                    </div>

                    <div class="relative z-0  w-full group">
                      <input
                        name="firstName"
                        type="text"
                        required
                        placeholder=" "
                        onChange={handleChange}
                        className="regularInput peer"
                      />
                      <label
                        for="firstName"
                        className="regularLabel pointer-events-none "
                      >
                        FIRST NAME*
                      </label>
                    </div>

                    <div class="relative z-0  w-full group">
                      <input
                        name="lastName"
                        type="text"
                        required
                        placeholder=" "
                        onChange={handleChange}
                        className="regularInput peer"
                      />
                      <label
                        for="lastName"
                        className="regularLabel pointer-events-none "
                      >
                        LAST NAME*
                      </label>
                    </div>

                    <div class="relative z-0  w-full group">
                      <input
                        name="email"
                        type="email"
                        required
                        placeholder=" "
                        onChange={handleChange}
                        className="regularInput peer"
                      />
                      <label
                        for="email"
                        className="regularLabel pointer-events-none "
                      >
                        EMAIL ADDRESS*
                      </label>
                    </div>
                    <div class="relative z-0  w-full group">
                      <input
                        name="phone"
                        type="text"
                        inputMode="tel"
                        required
                        placeholder=" "
                        onChange={handleChange}
                        className="regularInput peer"
                        //  pattern="\d*"
                      />
                      <label
                        for="phone"
                        className="regularLabel pointer-events-none "
                      >
                        PHONE NUMBER*
                      </label>
                    </div>

                    <div className="flex items-center col-span-2  relative text-beige text-[14px] md:text-[18px] font-corona mb-5 w-fit mx-auto">
                      <input
                        type="checkbox"
                        name="subscribe"
                        id="subscribe"
                        checked={checked}
                        onChange={handleChecked}
                      />
                      <label
                        for="subscribe"
                        className=" mx-auto font-bold letter-spacing-8 relative mt-4"
                      >
                        <div
                          className={cx(
                            " w-[33px] h-[33px] rounded-[5px] absolute top-2 -left-5 transition-all duration-300 ease-in-out ",
                            {
                              "bg-blue": checked,
                              "bg-beige drop-shadow-2xl border-2 border-blue":
                                !checked,
                            }
                          )}
                          onClick={() => handleSub(checked ? false : true)}
                        >
                          {checked && (
                            <Tick className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2" />
                          )}
                        </div>

                        <span className="block ml-8 ">
                          <span> I WANT TO HEAR FROM CORONA NZ</span>

                          <br />
                        </span>
                      </label>
                      <span
                        className="absolute -bottom-6 left-[47px] cursor-pointer underline underline-offset-4 "
                        onClick={() => setOpen(true)}
                      >
                        {" "}
                        TERMS & CONDITIONS*
                      </span>
                    </div>

                    <button
                      type="submit"
                      className="flex items-center gap-x-2 button-two"
                    >
                      <span>ENTER</span>
                      {isLoading && (
                        <div className="ml-4">
                          <Spinner />
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </motion.div>
      {/* terms modal starts */}
      <PortalModal isShowing={open}>
        <TermsPopup handleClose={handleClose} />
      </PortalModal>
      {/* confirmation modal starts */}
      <PortalModal isShowing={confirmationOpen}>
        <Confirmation handleConfirmationClose={handleConfirmationClose} />
      </PortalModal>

      {/* error modal starts */}
      <PortalModal isShowing={errorOpen}>
        <ErrorPopup handleErrorClose={handleErrorClose} error={error} />
      </PortalModal>
    </>
  );
};
