import React from 'react';
import CoronaLogo from '@images/corona-logo.png';
import WinMessage from '@images/win-message.png';

const CompText = ({ isStart }) => {
  return (
    <div className='pt-[50px] text-blue   text-center text-blue-dark relative z-30 px-gutter'>
      <div className='w-fit h-full mx-auto'>
        <img
          src={CoronaLogo}
          alt='corona logo'
          className='w-[127px] md:w-[186px] h-auto'
        />
      </div>
      <div className='w-fit h-full mx-auto mt-[17px]'>
        <img
          src={WinMessage}
          alt='corona logo'
          className='w-[196px] md:w-[418px] h-auto'
        />
      </div>
      <div>
        <h3 className='text-[34px] md:text-[70px] font-corona underline decoration-[3px] underline-offset-8'>
          A CORONA COOLER
        </h3>
      </div>
    </div>
  );
};

export default CompText;
